@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Anton&display=swap');

body {    
  margin: 0;
  font-family: Arial, sans-serif;    
  color: #FFD700;
}

.Main {
  background: #2a2a2a; /* Background color applied here */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%; /* Ensure the main container takes the full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 20px; /* Add padding for better spacing on small screens */
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center align text for better readability on small screens */
  max-width: 600px; /* Limit the width for better readability */
  padding: 80px;
}

.main-paragraph {
  width: 100%; /* Adjust to full width on small screens */
  text-align: center;
  margin: 0; /* Remove default margins */
  padding: 0 10px; /* Add padding for better spacing */
  color: #FFD700;
  font-family: "Anton", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
  line-height: 1.5; /* Adjust line height for consistent line lengths */
}
.main-image {
  max-width: 25%; /* Ensure the image doesn't exceed the container's width */
  height: auto; /* Maintain aspect ratio */
  padding-right: 15%; /* Keep a small padding for larger screens */
}

.coin-name {
  font-size: medium;
  font-weight: 500;
}






@media screen and (max-width: 768px) {
  .Main {
    flex-direction: column;
    height: 100vh; /* Ensure full height on small screens */
    padding: 10px; /* Add padding for better spacing */
  }
  .Header {
    width: 100%; /* Ensure the header takes full width on small screens */
    padding: 40px; /* Add some padding for better spacing */
  }
  .main-paragraph {
    font-size: 15px; /* Adjust font size for readability on small screens */
    line-height: 1.2; /* Adjust line height for readability */
    padding: 0 10px; /* Add padding for better spacing */
  }

  .main-image {
    padding-right: 0; /* Remove right padding on small screens */
    max-width: 40%; /* Adjust image size for small screens */
    margin-top: 20px; /* Add margin to separate from text */
  }
  .coin-name{
    font-size: 16px;
  }
}