@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

body {    
  margin: 0;
  font-family: Arial, sans-serif;    
  background-color: #FFD700;
  color: black;
}

.hero {    
  position: relative;
  height: 100vh;    
  display: flex;
  align-items: center;    
  justify-content: center;
  text-align: center;
}

.hero-title {    
  font-size: 4em;
  font-weight: bold;    
  margin: 0;
  font-family: "Alfa Slab One", serif;
  color: #2a2a2a;
}

.hero-subtitle {
  font-size: 1.5em;    
  margin: 20px 0;
}

.hero-image {
  position: absolute;    
  bottom: 0;
  left: 0;    
  width: 28%;
  height: auto;
  object-fit: cover;
  z-index: -1;
}

.icon-row {    
  display: flex;
  justify-content: center;    
  margin: 20px 0;
}

.icon-row img {
  width: 50px;    
  margin: 0 15px;
}

.copyable-text {
  color: #2a2a2a; 
}

.copyable-text:hover {
  cursor: pointer;
}

.copied-message {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

.hero-content {
  margin-left: 200px; /* Adjust this value to control the gap */
}

.Link {
  display: inline-block;
  margin: 0 15px;
  transition: transform 0.3s ease, color 0.3s ease; /* Added color transition */
}

.Link:hover {
  transform: scale(1.2);
  color: white; /* Change color to white on hover */
}

/* Responsive styles for mobile */
@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column; /* Stack content vertically */
    height: 100vh; /* Ensure it takes the full viewport height */
    justify-content: space-around; /* Distribute space evenly */
    align-items: center; /* Center align items */
    padding: 20px; /* Add some padding for better spacing */
  }

  .hero-image {
    width: 34%;
    height: auto;
    object-fit: cover;
  }

  .hero-content {
    position: relative; /* Ensure content is above the image */
    margin-left: 0; /* Remove left margin */
    margin-top: -120px; /* Adjust the top margin to move content up */
  }

  .hero-title {
    font-size: 2em; /* Reduce font size for mobile */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }

  .icon-row img {
    width: 30px; /* Reduce icon size */
    margin: 0 10px; /* Adjust margin for spacing */
  }

  .contract-address {
    font-size: 0.8em; /* Adjust font size */
  }

  .copyable-text {
    font-size: 0.8em; /* Adjust font size */
  }

  .copied-message {
    font-size: 0.8em; /* Adjust font size for the message */
    padding: 5px; /* Adjust padding */
  }

  .contract-address-top {
    display: block;
  }

  .contract-address-bottom {
    display: none;
  }
}

/* Desktop styles */
@media screen and (min-width: 769px) {
  .contract-address-top {
    display: none;
  }

  .contract-address-bottom {
    display: block;
    font-size: 1em; /* Adjust font size for desktop */
  }

  .copyable-text {
    font-size: 1em; /* Adjust font size for desktop */
  }
}